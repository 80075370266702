














































































import Vue from 'vue';
import Component from 'vue-class-component';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import Excel from 'exceljs';
import API, { Types } from '../modules/API';
import {Reports, User, Global } from '@/store';
import PropUtils from '@/modules/PropUtils';
import { Watch } from 'vue-property-decorator';

@Component({
    components: {ConfirmDialog}
})
export default class SetPartNumByDeviceId extends Vue {
    @Reports.State('reportsList') devices;
    @Reports.State('devicesCount') devicesCount;
    @Global.State('readonly_user') readonly_user;
    @Global.Mutation('setPageTitle') setPageTitle;

    user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

    // general functionality
    loading = false;
    MAIN_COLUMN = 'id';
    prop_utils = PropUtils;

    // used on mounted
    saved_columns = ['part_number']; // columns' possible values, populated from API (the <select> HTML object)
    get names_not_used() { // computing to only show the names for fields that were not selected yet.
        const array_of_relations = this.columns_relations.map((item) => item.nameInDB);
        const updated_array_names_not_used = this.saved_columns.filter((item) => array_of_relations.indexOf(item) === -1);
        return updated_array_names_not_used;
    }

    // variables to read excel to Javascript's handling, used after uploading excel file by user
    workbook: any = null;
    worksheets = [];
    sheetNumber = -1;

    // table variables after reading excel
    table = [];
    columns_relations = []; // the <th>s cells of the colum. array of objects.
    device_id_column = -1; // the main column is the device_id_column. if it's "-1" then it's uninitialized
    showTooltipSetColumn = false;
    
    // dialog props
    showDialog = false;
    dialogText = '';
    dialogType = null;
    dialogCancelText = 'Cancel';
    logs = []; // after uploading 
    devicesFoundInSheet = 0;
    
    poles_units = [];
    device_ids = [];

    mounted() {
        this.setPageTitle('Set Part Number By Device ID');
        this.getExistDeviceIds();
    }

    @Watch('devices')
    @Watch('devicesCount')
    getExistDeviceIds() {
        if (this.devices.length !== this.devicesCount) return;
        this.devices.forEach((device) =>  this.device_ids.push(device.id));
    }

    async loadExcel(event) {
        // reset state 
        this.loading = true;
        this.logs = [];
        this.worksheets = [];
        this.sheetNumber = -1;
        this.device_id_column = -1;
        this.table = [];
        this.columns_relations = [];
        
        const wb = new Excel.Workbook(), 
        reader = new FileReader(),
        that = this;
        reader.readAsArrayBuffer(event.target.files[0]);
        reader.onload = () => {
            const buffer = reader.result;
            if (typeof buffer !== 'string'){
                wb.xlsx.load(buffer).then(async (workbook) => {
                    workbook.eachSheet((sheet) => this.worksheets.push(sheet.name));
                    that.workbook = workbook;
                    that.loading = false;
                    this.openModal('choose_worksheet');
                });
            }
        };
    }

    styleCell(cell, index: number) {
        if (index === this.device_id_column) {
            return this.device_ids.includes(cell.text.toString().trim())
                ? 'background-color: green; color: white;'
                : 'background-color: red; color: white;';
        }
        return '';
    }

    async loadSheet(sheetNumber: number) {
        // reset state
        this.devicesFoundInSheet = 0;
        this.table = [];
        this.sheetNumber = sheetNumber;
        this.columns_relations = [];
        this.loading = true;
        this.device_id_column = -1;

        const sheet = await this.workbook.worksheets[sheetNumber];
        if (sheet === undefined) alert(this.$t('Error choosing worksheet. Please check the file or decrease its size by separating sheets and try again.'));
        await sheet.getRow(1).eachCell({includeEmpty: true}, (cell: string, cellIndex: number) => this.columns_relations.push({nameInFile: cell, nameInDB: null, key: ('head' + cellIndex.toString())}));
        await sheet.eachRow(async (row, rowIndex: number) => {
            const rowArray = [];
            row.eachCell({ includeEmpty: true }, (cell, cellIndex: number) => rowArray.push({text: cell.toString() || '', key: ('cell' + cellIndex.toString() + rowIndex.toString())}));
            this.table.push(rowArray);
            this.openModal('choose_device_id');
        });
        this.loading = false;
    }

    defineAsDeviceIdColumn(i) {
        if (i > -1) this.openModal('set_device_id');
        if (this.device_id_column > -1) this.columns_relations[this.device_id_column].nameInDB = null;
        this.device_id_column = i;
        if (this.device_id_column > -1) this.columns_relations[i].nameInDB = this.MAIN_COLUMN;
    }


    async saveInDB() {
        this.loading = true;
        const device_id_index = this.columns_relations.findIndex((item, i) => item.nameInDB && item.nameInDB === this.MAIN_COLUMN);
        if (device_id_index === -1) return;

        const part_number_index = this.columns_relations.findIndex((item, i) => item.nameInDB && item.nameInDB === 'part_number');
        if (part_number_index === -1) return;

        await this.table.forEach(async (row, i) => {
            if (row[this.device_id_column]) {
                const device_id = row[this.device_id_column] && row[this.device_id_column].text 
                    ? row[this.device_id_column].text.toString().trim()
                    : null;
                if (device_id && this.device_ids.includes(device_id)){
                    const part_number = row[part_number_index] && row[part_number_index].text && row[part_number_index].text.toString().trim()
                        ? row[part_number_index].text.toString().trim()
                        : '';
                    if (!part_number) return;

                    const url = `/${device_id}/properties`;
                    const response = await API.patch(
                        Types.DEVICES,
                        url, 
                        { part_number }, 
                        { query: {name: 'meta.part_number'} });
                    if (!response['value']) this.logs.push(`problem with row number ${ (i + 1) } with device id ${ device_id }: unknown internal error.`);
                    else console.log(`response value: ${JSON.stringify(response['value'])}`);
                }else
                    this.logs.push(`problem with row number ${ (1 + i) }. ${this.MAIN_COLUMN} ${row[this.device_id_column].text.toString()} is not present in any of the units queried for the project.`);
            }
            else {
                this.logs.push(`problem with row number ${(i + 1)}: ${this.MAIN_COLUMN} is not a number, it's ${row[this.device_id_column] ? row[this.device_id_column].text.toString() : 'null'}.`);
            }
        });
        this.loading = false;
        this.openModal('saved_to_db');
    }

    setFieldsToRow(row, selected_indexes) {
        const formData = {};
        selected_indexes.forEach((index) => {
            const value = row && row[index] && row[index].text ? row[index].text : null;
            formData[(this.columns_relations[index].nameInDB)] = value;
        });
        return formData;
    }

    
    // Dialog functions 
    openModal(type: string) {
        this.dialogType = type;
        this.showDialog = true;
        if (type === 'saved_to_db') {
            this.dialogText = this.$t('The file has been received successfully');
            this.dialogCancelText = this.$t('Download Log File');
        }
        if (type === 'set_device_id') this.dialogText = this.$t(`Are you sure that you want to mark this column as Device Id? a mistake will cause errors because this is a main indication`);
        if (type === 'save_in_db') this.dialogText = this.$t('Are you sure that you want to save the uploaded Excel file to the system?');
        if (type === 'choose_worksheet') this.dialogText = this.$t('Please choose the tab you would like to upload first');
        if (type === 'choose_device_id') this.dialogText = this.$t(`Please choose the column of Device Id to function as the main column`);
    }

    cancelModalAction() {
        if (this.dialogType === 'set_device_id') this.defineAsDeviceIdColumn(-2);
        if (this.dialogType === 'save_in_db') this.dialogType = null;
        if (this.dialogType === 'saved_to_db') this.downloadLog();
        this.closeModal();
    }
    
    closeModal() {
        if (this.dialogType === 'set_device_id' && this.device_id_column > -1) {
            this.showTooltipSetColumn = true; 
            this.setValidPolesBySheet();
        }
        if (this.dialogType === 'save_in_db') this.saveInDB();
        this.dialogType = null;
        this.showDialog = false;
        this.dialogText = '';
        this.dialogCancelText = 'Cancel';
    }

    setValidPolesBySheet(){
        const poleColumnValues = this.table.map((row) => row[this.device_id_column]);
        let validPoleNumbers = poleColumnValues.map((value) => {
            return this.poles_units.indexOf(Number(value.text.toString())) !== -1 
                ? Number(value.text.toString())
                : null;
        });

        validPoleNumbers = validPoleNumbers.filter((pole_number) => pole_number !== null);
        this.devicesFoundInSheet = this.poles_units.reduce((acc, current_pole) => {
            return validPoleNumbers.includes(current_pole)
                ? acc + 1 
                : acc ;
        }, 0);
    }   

    downloadLog() {
        const date = new Date(), type = 'text/plain', 
        name = `logs_${date.getMinutes().toString()}_${date.getHours().toString()}_${date.getMonth().toString()}_${date.getFullYear().toString()}.txt`,
        text = this.logs.join('  \n'),
        blob = new Blob([text], {type}),
        url = window.URL.createObjectURL(blob),
        link = document.createElement('a');
        link.download = name;
        link.href = url;
        link.click();
        this.logs = [];
    }
}
